import React,{useState, useEffect} from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./Homepage.css";
import Testimonial from "./Testimonial";
import '@fortawesome/fontawesome-free/css/all.min.css';
import Achievement from "./Achievement";
import Appointment from "./Appointment";
import Services from "./Services";
import About from "./About.js";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Loader from "./Loader.js";
import Achievementbrief from "./Achievementbrief.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HomePage=()=>{
    
    useEffect(()=>{
    document.title="Home | Physiora"
  },[]);

  const [formData, setFormData] = useState({
    Appointmenttab_id: 0, // Initial id value
    Appointmenttab_name: '',
    Appointmenttab_email: '',
    Appointmenttab_date: new Date(),
    Appointmenttab_time: '', // Default time
    Appointmenttab_doctorname: '', 
    Appointmenttab_phone: '',
    Appointmenttab_age: '',
    Appointmenttab_type: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [timeOptions1, setTimeOptions1] = useState([]);
  const [doctorName, setDoctorName] = useState('');
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  
  useEffect(() => {
    const fetchDoctorOptions = () => {
      const doctorRef =firebase.database().ref('Doctor Name');
      doctorRef.once('value', (snapshot) => {
        const doctors = snapshot.val();
        if (doctors) {
          const doctorNames = Object.values(doctors);
          setDoctorOptions(doctorNames);
        }
      });
    };
    fetchDoctorOptions();
  }, []);
  
  const handleTimeChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, Appointmenttab_time: value });
  };

  
  const handleDoctorNameChange = (e) => {
    const { value } = e.target;
    setDoctorName(value); // Update doctorName state
    setFormData({ ...formData, Appointmenttab_doctorname: value }); // Update formData state
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, Appointmenttab_phone: value });
  };

  const handlePaymentIdChange = (e) => {
    setSelectedPaymentId(e.target.value);
    setFormData({ ...formData, PaymentId: e.target.value });
  };
  

  const [paymentOptions, setPaymentOptions] = useState([]);
const [selectedPaymentId, setSelectedPaymentId] = useState('');

useEffect(() => {
  if (modalVisible) {
    const fetchPaymentOptions = async () => {
      try {
        const currentUser = firebase.auth().currentUser;

        if (currentUser) {
          const userId = currentUser.uid;
          
          const paymentsRef = firebase.database().ref('/paymentssuccess');
          const snapshot = await paymentsRef.once('value');
          const payments = snapshot.val();

          if (payments) {
            // Filter payments based on userId and appointmentStatus
            const options = Object.values(payments)
              .filter(payment => payment.userId === userId && payment.appointmentStatus === 'No')
              .map(payment => ({
                id: payment.paymentId,
                timestamp: payment.timestamp,
              }));

            setPaymentOptions(options);
          }
        } else {
          console.error('No user is currently authenticated.');
        }
      } catch (error) {
        console.error('Error fetching payment data:', error);
      }
    };

    fetchPaymentOptions();
  }
}, [modalVisible]);


  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Store form data in Realtime Database

    try {
      const snapshot = await firebase.database().ref('Appointment_tab').orderByChild('Appointmenttab_id').limitToLast(1).once('value');
      let latestAppointment = null;
      snapshot.forEach((childSnapshot) => {
        latestAppointment = childSnapshot.val();
      });

      console.log("Latest appointment:", latestAppointment);

      // Calculate the new ID
      const newAppointmentId = latestAppointment ? latestAppointment.Appointmenttab_id + 1 : 1;

      console.log("New appointment ID:", newAppointmentId);
      const updatedFormData = {
        ...formData,
        Appointmenttab_id: newAppointmentId,
        Appointmenttab_doctorname: 'Dr.C S Aravinda PT',
        PaymentId: selectedPaymentId
      };

      updatedFormData.Appointmenttab_date = new Date(updatedFormData.Appointmenttab_date).toISOString();

      await firebase.database().ref('Appointment_tab').push(updatedFormData);

      console.log('Sending email with data:', {
        name: formData.Appointmenttab_name,
        date: new Date(formData.Appointmenttab_date).toLocaleString(), 
        time: formData.Appointmenttab_time,
        doctorName: formData.Appointmenttab_doctorname,
        phone: formData.Appointmenttab_phone,
        email: formData.Appointmenttab_email,
        age: formData.Appointmenttab_age,
        type: formData.Appointmenttab_type,
        paymentId: selectedPaymentId
      });

      const emailData = {
        name: formData.Appointmenttab_name,
        date: formData.Appointmenttab_date,
        time: formData.Appointmenttab_time,
        doctorName: "Dr.C S Aravinda PT",
        phone: formData.Appointmenttab_phone,
        email: formData.Appointmenttab_email,
        age: formData.Appointmenttab_age,
        type: formData.Appointmenttab_type,
        defaultemail: "karan@datadna.in",
        paymentId: selectedPaymentId
      };

      console.log('Sending email with data:', emailData); 

      const response = await axios.post('https://us-central1-the-physiora.cloudfunctions.net/api/send-email', emailData);

      if (response.status !== 200) {
        throw new Error('Failed to send email');
      }

      if (selectedPaymentId) {
        const paymentRef = firebase.database().ref(`/paymentssuccess/${selectedPaymentId}`);
        await paymentRef.update({
          appointmentStatus: 'Yes',
          patientName: formData.Appointmenttab_name,
          phone: formData.Appointmenttab_phone,
          date: new Date(formData.Appointmenttab_date).toLocaleString(),
          AppointmentDate: formData.Appointmenttab_date,
          AppointmentTime: formData.Appointmenttab_time,
        });
      }

      setFormData({
        Appointmenttab_id: '',
        Appointmenttab_name: '',
        Appointmenttab_email: '',
        Appointmenttab_date: new Date(),
        Appointmenttab_time: '',
        Appointmenttab_doctorname: '',
        Appointmenttab_phone: '',
        Appointmenttab_age: '',
        Appointmenttab_type: '',
        PaymentId: '' 
      });
      setSuccessMessage('Appointment successfully scheduled!');
      setTimeout(() => {
        setSuccessMessage('');
        setModalVisible(false);
      }, 2000);
    } catch (error) {
      console.error('Error storing form data:', error);
    }
  };
   
  
  const defaultTimeOptions = [
    "10:00 AM - 10:30 AM",
    "10:30 AM - 11:00 AM",
    "11:00 AM - 11:30 AM",
    "11:30 AM - 12:00 AM",
    "12:00 PM - 12:30 PM",
    "12:30 PM - 01:00 PM",
    "01:00 PM - 01:30 PM",
    "01:30 PM - 02:00 PM",
    "06:00 PM - 06:30 PM",
    "06:30 PM - 07:00 PM",
    "07:00 PM - 07:30 PM",
    "07:30 PM - 08:00 PM",
    "08:00 PM - 08:30 PM",
    "08:30 PM - 09:00 PM",
  ];
  
  useEffect(() => {
    const fetchTimeOptions = () => {
      if (formData.Appointmenttab_date) {
        const adminRef = firebase.database().ref('adminData');
        adminRef.once('value', (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const timeRanges = Object.values(data)
              .filter((item) => item.date === formData.Appointmenttab_date.toLocaleDateString('en-US'))
              .map((item) => item.timeRange);
            setTimeOptions1([...defaultTimeOptions,...timeRanges]);
          }
        });
      }
    };
    fetchTimeOptions();
  }, [formData.Appointmenttab_doctorname, formData.Appointmenttab_date]);

  const handleAppointmentClick = async () => {
    const currentUser = firebase.auth().currentUser;
  
    if (!currentUser) {
      toast.error('Kindly Please Login', {
        autoClose: 3000,
        style: {
          backgroundColor: '#f5626e',
          color: 'black',
          whiteSpace: 'nowrap',
        },
        closeButton: true,
        onClose: () => {
          window.location.href = '/auth';
        },
      });
    } else {
      try {
        const userId = currentUser.uid;
        const paymentsRef = firebase.database().ref('/paymentssuccess');
        const snapshot = await paymentsRef.once('value');
        const payments = snapshot.val();
  
        if (payments) {
          const options = Object.values(payments)
            .filter(payment => payment.userId === userId && payment.appointmentStatus === 'No')
            .map(payment => ({
              id: payment.paymentId,
              timestamp: payment.timestamp,
            }));
  
          if (options.length > 0) {
            setPaymentOptions(options);
            setModalVisible(true);
          } else {
            window.location.href = '/PaymentPage';
          }
        } else {
          window.location.href = '/PaymentPage';
        }
      } catch (error) {
        console.error('Error fetching payment data:', error);
        window.location.href = '/PaymentPage';
      }
    }
  };
  
  // const handleAppointmentClick = () => {
  
  
  //     setModalVisible(true);
  //   }
 


    return(
        <>
        <div className="App">
            <Header/>
            <Loader/>
             {/* Slider Area */}
            <div className="slider">
                <div className="hero-slider">
                {/* Start Single Slider */}
                <div
                    className="single-slider"
                  
                >
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                        <div className="text">
                            <h1>
                            We Provide <span>Physio</span> Services That You Can{" "}
                            <span>Trust!</span>
                            </h1>
                            <p>
                            Enhance your mobility and reduce pain with our expert physiotherapy services, tailored to meet your unique needs.{" "}
                            </p>
                            <div className="but">
                            <a   data-toggle="modal" className="btn"   data-target="#modalRequest"  onClick={handleAppointmentClick}  style={{backgroundColor:"#853f91"}}>
                                Get Appointment
                            </a>
                            
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* End Single Slider */}
                {/* Start Single Slider */}
                {/* <div
                    className="single-slider"
                    style={{ backgroundImage: 'url("img/slider.jpg")' }}
                >
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                        <div className="text">
                            <h1>
                            We Provide <span>Medical</span> Services That You Can{" "}
                            <span>Trust!</span>
                            </h1>
                            <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Mauris sed nisl pellentesque, faucibus libero eu, gravida
                            quam.{" "}
                            </p>
                            <div>
                            <a href="#" className="btn">
                                Get Appointment
                            </a>
                            <a href="#" className="btn primary">
                                About Us
                            </a>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div> */}
                {/* Start End Slider */}
                {/* Start Single Slider */}
                {/* <div
                    className="single-slider"
                    style={{ backgroundImage: 'url("img/slider3.jpg")' }}
                >
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                        <div className="text">
                            <h1>
                            We Provide <span>Medical</span> Services That You Can{" "}
                            <span>Trust!</span>
                            </h1>
                            <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Mauris sed nisl pellentesque, faucibus libero eu, gravida
                            quam.{" "}
                            </p>
                            <div>
                            <a href="#" className="btn">
                                Get Appointment
                            </a>
                            <a href="#" className="btn primary">
                                Conatct Now
                            </a>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div> */}
                {/* End Single Slider */}
                </div>
            </div>
  {/*/ End Slider Area */}
            <div className="schedule">
            <div className="container">
                <div className="schedule-inner">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12 ">
                    {/* single-schedule */}
                    <div className="single-schedule first">
                        <div className="inner">
                        <div className="icon">
                            <i className="fa fa-ambulance" />
                        </div>
                        <div className="single-content">
        
                            <h4>Flexible Time</h4>
                            <p style={{paddingBottom:"70px"}}>
                            Our dedicated team of physiotherapists is here to provide immediate care for urgent physiotherapy needs.On all working days
                            </p>
                            {/* <a href="">
                            LEARN MORE
                            <i className="fa fa-long-arrow-right" />
                            </a> */}
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                    {/* single-schedule */}
                    <div className="single-schedule middle">
                        <div className="inner">
                        <div className="icon">
                            <i className="fa fa-user-md" />
                        </div>
                        <div className="single-content">
                            <h4>Doctors Timetable</h4>
                            <p style={{textAlign:"justify",paddingBottom:"70px"}}>
                            Timetable may be subject to changes. Please check for the latest updates or call us for confirmation before your visit.
                            </p>
                            {/* <a href="">
                            LEARN MORE
                            <i className="fa fa-long-arrow-right" />
                            </a> */}
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-12">
                    {/* single-schedule */}
                    <div className="single-schedule last">
                        <div className="inner">
                        <div className="icon">
                            <i className="fa fa-clock o" />
                        </div>
                        <div className="single-content">
                            <h4>Opening time</h4>
                            <ul className="time-sidual">
                            <li className="day">
                                Opening Hours 
                                <span>7 AM to 9 PM</span>
                            </li>
                            <h4 style={{fontSize:"15px"}}>Consulting Hours (Monday to Saturday)</h4>
                    
                            <li className="day mt-2">
                               Morning <span>10 AM to 2 PM</span>
                              
                            </li>
                            <li className="day mt-2">
                               Evening <span>6 PM to 9 PM</span>
                              
                            </li>
                            <li className="day" style={{marginTop:"14px"}}>
                                Sunday <span>Prior Appointments</span>
                            </li>
                            </ul>
                            {/* <a href="" style={{marginDown:"5px"}}>
                            LEARN MORE
                            <i className="fa fa-long-arrow-right" />
                            </a> */}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <About/>
            <Services/><br></br><br></br>
            <Testimonial/><br></br>
            <Achievement/>
            <Achievementbrief/>
           
            <Footer/>
        </div>
        <ToastContainer />
        
        {modalVisible && (
            <div className="modal" tabIndex={-1} role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modalRequestLabel" style={{color:"#853f91"}}>Make an Appointment</h5>
                <button type="button" style={{borderColor:"#853f91",borderColor:"20px", width:"50px",backgroundColor:"#853f91"}} className="close" onClick={() => setModalVisible(false)}>
                  <span aria-hidden="true">X</span>
                </button>
              </div>
              <div className="modal-body">
              <form onSubmit={handleSubmit}>
  <div className="row">
    <div className="col-md-6">
      <div className="form-group1">
        <input
          type="text"
          className="form-control"
          name="Appointmenttab_name"
          value={formData.Appointmenttab_name}
          onChange={handleInputChange}
          placeholder="Name"
        />
      </div><br></br>
    </div>
    <div className="col-md-6">
      <div className="form-group1">
        <input
          type="email"
          className="form-control"
          name="Appointmenttab_email"
          value={formData.Appointmenttab_email}
          onChange={handleInputChange}
          placeholder="Email"
        />
      </div>
    </div>
  </div>
  
  <div className="row">
    <div className="col-md-6">
      <div className="form-group1">
      <PhoneInput
        name="Appointmenttab_phone"
        placeholder="Phone Number"
        value={formData.Appointmenttab_phone}
        onChange={handlePhoneChange}
        className="form-control1"
        defaultCountry="IN" 
        required
      />
      </div><br></br>
    </div>
    <div className="col-md-6">
      <div className="form-group1" style={{marginTop:"5px"}}>
        <DatePicker
          selected={formData.Appointmenttab_date}
          onChange={(date) => setFormData({ ...formData, Appointmenttab_date: date })}
          dateFormat="dd-MM-yyyy"
          className="form-control1"
          placeholderText="Select Date"
          minDate={new Date()}
        />
      </div>
    </div>
  </div>
  <div className="row">
      <div className="col-md-6" >
          <div className="form-group1" style={{marginBottom:"15px"}}>
            <label style={{color:"#853f91",fontSize:"20px"}}>Appointment Type</label>
              <div>
                <input
                  type="radio"
                  name="Appointmenttab_type"
                  value="Online"
                  checked={formData.Appointmenttab_type === "Online"}
                  onChange={handleInputChange}
                  
                /> Online {""}
                <input
                  type="radio"
                  name="Appointmenttab_type"
                  value="Offline"
                  checked={formData.Appointmenttab_type === "Offline"}
                  onChange={handleInputChange}
                  style={{marginLeft:"20px"}}
                />{""} Offline
                </div>
                </div>
              </div>
              <div className="col-md-6" style={{marginTop:"10px"}}>
                <div className="form-group1">
                  <input
                    type="number"
                    className="form-control"
                    name="Appointmenttab_age"
                    value={formData.Appointmenttab_age}
                    onChange={(e) => {
                      if (e.target.value <= 120) {
                        handleInputChange(e);
                      }
                    }}
                    placeholder="Age"
                    max="120"
                  />
                </div>
                <br />
              </div>
            </div>
  
  <div className="row">
    <div className="col-md-6">
      <div className="form-group1">
        <select
          className="form-control"
          name="Appointmenttab_time"
          value={formData.Appointmenttab_time}
          onChange={handleTimeChange}
          placeholder="Select Time"
          style={{width:"210px"}}
        >
          <option value="">Select Time</option>
          {timeOptions1.map((time, index) => (
            <option key={index} value={time}>{time}</option>
          ))}
        </select>
      </div>
    </div>
    <div className="col-md-6">
      <div className="form-group1">
        <select
          className="form-control"
          name="Appointmenttab_doctorname"
          value={formData.Appointmenttab_doctorname}
          onChange={handleDoctorNameChange}
          placeholder="Select Doctor"
          style={{width:"210px"}}
        >
          <option value="">Select Doctor</option>
          {doctorOptions.map((doctor, index) => (
            <option key={index} style={{ color: 'black' }}>{doctor}</option>
          ))}
        </select>
      </div><br></br>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="form-group1">
          <select
            className="form-control"
            name="PaymentId"
            value={selectedPaymentId}
            onChange={handlePaymentIdChange}
            placeholder="Select Payment ID"
            style={{ width: "100%" }}
          >
            <option value="">Select Payment ID</option>
            {paymentOptions.map((payment, index) => (
              <option key={index} value={payment.id}>
                {payment.id} - {payment.timestamp}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>

  </div>
  
  <div className="form-group1 text-center">
    <button
      type="submit"
      className="btn btn-primary"
      style={{backgroundColor:"#853f91", borderColor:"#853f91"}}
    >
      Make an Appointment
    </button>
  </div>
  
  {successMessage && <p className="text-success text-center">{successMessage}</p>}
</form>

              </div>
            </div>
          </div>
        </div>
        
          )}
          </>
    );
}

export default HomePage;